<template>
<div>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard class="p-4" style="min-width:300px; max-width:300px; background-color:#3c2468; border:none" 
            text-color="white">
            <CCardBody>
              <CForm>
                <h1 style="color:white; font-size:26px;">Gesto</h1>
                <p class="text-muted" style="color:white">Reestablecer su Contraseña</p>
                <CInput
                  placeholder="Rut Empresa"
                  autocomplete="Rut Empresa"
                  v-model="usuario.rutEmpresa"
                  :is-valid="rutValidado"
                  @keyup="formatearIngresoRut"
                  @blur="validarRut"
                  @keyup.enter.native="resetearPsw"
                ></CInput>
                <CInput
                  placeholder="Usuario"
                  autocomplete="Usuario"
                  v-model="usuario.username"
                  @keyup.enter.native="resetearPsw"
                ></CInput>
                <CRow>
                  <CCol col="12" class="text-left">
                    <CAlert style="max-width:250px" closeButton v-show="showMensaje" :color="tipoMensaje">{{mensajeLogin}}</CAlert>
                  </CCol>
                </CRow>
                <CRow v-show="!showMensajeCambioPass">
                  <CCol col="6" class="text-rigth"></CCol>
                  <CCol col="6" class="text-rigth">
                    <CButton style="background-color:#0b7689;color:white;" class="px-4" @click="resetearPsw" :disabled="isReestablecer">
                      
                      {{msjSolicitandoCambio}}
                    </CButton>
                  </CCol>
                </CRow>
                
              </CForm>
            </CCardBody>
          </CCard>

          <!--<CCard
            color="primary"
            text-color="white"
            class="text-center py-5 d-sm-down-none"
            body-wrapper
          >
            <h1>Sistema de Reportes VAPP</h1>
            <p>Para ingresar utilice su usuario y contraseña otorgado por vapp, en caso de no disponer uno contacte a soporte de vapp al correo soporte@vapp.cl</p>
            <CButton
              color="primary"
              class="active mt-3"
            >
              Register Now!
            </CButton>
          </CCard>-->
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
  </div>
</template>

<style>

  .imgr{
      -webkit-animation: 3s rotate linear infinite;
      animation: 3s rotate  linear infinite;
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
  }
</style>

<script>
  import cons from '@/const'
  import axios from "axios"
  
  import { freeSet } from '@coreui/icons'

export default {
    name: 'ReestablecerPsw',
    freeSet,
    data () {
        return {
            isReestablecer: false,
            rutValidado: false,
            mensajeLogin: "",
            showMensaje: false,
            showMensajeCambioPass: false,
            enviandoSolicitud: false,
            msjCambiarPass: "",
            msjSolicitandoCambio: "Resetear Contraseña",
            tipoMensaje: "",
            usuario: {
                username: null,
                rutEmpresa: null
            },
        }
    },
    methods: {
        resetearPsw: function(){
            this.isReestablecer = true
            if(this.usuario.username == null 
            || this.usuario.rutEmpresa == null 
            || this.usuario.username == "" 
            || this.usuario.rutEmpresa == ""){
                
            this.showMensaje = true
            this.mensajeLogin = "Ingrese correctamente sus datos"
            this.tipoMensaje = "danger"
            this.isReestablecer = false

            } else{

            this.enviandoSolicitud = true
            this.msjSolicitandoCambio = "Enviando Solicitud"

            var url = cons.port+"://" + cons.ipServer + "/resetear_psw_usuario"

            let userFormateado = this.formatoRut(this.usuario.username)
            const rutRegex = /^(\d{1,3}(?:\.\d{3})*)-([\dkK])$/;
            let usaRut = false
            if (rutRegex.test(userFormateado)) {
                usaRut = true // No cumple con el formato del RUT
            }
                
            axios({
                method: "POST", 
                "url": url,
                "data": {
                        "tipoUser": this.$route.query.user || null,
                        "idUsuario": null,
                        "username": (usaRut)?userFormateado.replace(/\./g, ''):this.usuario.username,
                        "usaRut": usaRut,
                        "rutEmpresa": this.usuario.rutEmpresa.replace(/\./g, '')
                    
                }
            }).then(result => {

                this.$notification.success(result.data.mensaje, {  timer: 10, position:"bottomRight"});
                this.isReestablecer = false
                this.msjSolicitandoCambio = "Resetear Contraseña"
                    
            }, error => {
                this.$notification.error(error.response.data.mensaje, {  timer: 10, position:"bottomRight"});
                this.isReestablecer = false
                this.msjSolicitandoCambio = "Resetear Contraseña"
            })
            
            .finally(() => {
                
            });
            }
        },
        formatearIngresoRut: function(){
            this.usuario.rutEmpresa = this.formatoRut(this.usuario.rutEmpresa)
            this.validarRut()
        },
        validarRut:function(event){
            var rutCompleto = this.usuario.rutEmpresa.replace(/\./g, '');//replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
            
            if(rutCompleto == "" || rutCompleto == null){
                this.rutValidado = false
            } else{

            rutCompleto = rutCompleto.replace(/-/g,"-");

            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)){
                this.rutValidado =  false;
                return
            }

            var tmp = rutCompleto.split('-');
            var digv = tmp[1]; 
            var rut = tmp[0];

            if ( digv == 'K' ) digv = 'k' ;
            this.rutValidado = (this.validarDv(rut) == digv )
            }
        },
        validarDv: function(T){
            var M=0,S=1;
            for(;T;T=Math.floor(T/10))
                S=(S+T%10*(9-M++%6))%11;
            return S?S-1:'k';
        },
        formatoRut: function(rut){
                var sRut1 = rut.replace(/\./g,"").replace(/-/g,"");      //contador de para saber cuando insertar el . o la -
                var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
                var sInvertido = ""; //Guarda el resultado final del rut como debe ser
                var sRut = "";
                for(var i=(sRut1.length-1); i>=0; i-- ){
                    sInvertido += sRut1.charAt(i);
                    if (i == sRut1.length - 1 )
                        sInvertido += "-";
                    else if (nPos == 3)
                    {
                        sInvertido += ".";
                        nPos = 0;
                    }
                    nPos++;
                }
                for(var j = sInvertido.length - 1; j>= 0; j-- )
                {
                    if (sInvertido.charAt(sInvertido.length - 1) != ".")
                        sRut += sInvertido.charAt(j);
                    else if (j != sInvertido.length - 1 )
                        sRut += sInvertido.charAt(j);
                }
                //Pasamos al campo el valor formateado
                //rut.value = sRut.toUpperCase();
                return sRut.toUpperCase();
            },
    }
}
</script>
